/********************
  Header Css Styles *
  *******************/
.header {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  display: flex;
  width: calc(100% - 250px);
  position: fixed;
  z-index: 100;
  top: 0px;
  right: auto;
  right: 0px;
  color: rgb(255, 255, 255);
  backdrop-filter: blur(6px);
  background-color: rgba(249, 250, 251, 0.8);
  box-shadow: none;
}
.header .header-content {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 90px;
}
.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-wrapper .nav-icons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--second-color);
}
.nav-icons .left {
  width: 20%;
}
.nav-icons .left > span {
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  align-items: center;
  vertical-align: middle;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  font-size: 1.2rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgb(99, 115, 129);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 40px;
  height: 40px;
}
.nav-icons .left > span:hover {
  background-color: #ddd;
}

.nav-icons .menu-btn {
  display: none;
}

.nav-icons .search-btn {
  /* margin-left: 15px; */
  display: inline-flex;
}

.nav-icons .right {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
}
.nav-icons .nav-icon {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgb(99, 115, 129);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 40px;
  height: 40px;
}
.nav-icons .nav-icon:hover {
  background-color: #ddd;
}

.nav-icons .nav-icon i {
  font-size: 20px;
}
.nav-icons .nav-icon .badge {
  display: flex;
  align-items: center;
  position: absolute;
  top: -2px;
  right: 0px;
  background-color: var(--primary-color);
  font-weight: 600;
  font-size: 0.75rem;
  min-width: 20px;
  line-height: 1;
  padding: 0px 6px;
  height: 20px;
  border-radius: 10px;
  z-index: 1;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
}

/* Lang - Dropdown */
.header-content .lang-container {
}
.lang-container .lang-icon {
  cursor: pointer;
  position: relative;
}
.lang-container .lang-icon img {
  width: 30px;
  height: 30px;
}
.lang-container .dropdown__menu {
  top: -25px !important;
  right: 0 !important;
}
.lang-container .dropdown__menu .dropdown-item {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}
.lang-container .dropdown__menu .dropdown-item > img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
/* User - Dropdown */
.header-content .user-container {
  position: relative;
  margin-left: 10px;
  display: inline-flex;
}
.header-content .user-icon {
  position: relative;
  cursor: pointer;
}
.header .header-content .user-icon img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* Search */
.search-bar {
  display: none;
}
.search-bar.active {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.search-bar .search-overhide {
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100%;
}
.search-bar .wrrapper-content {
  backdrop-filter: blur(6px);
  background-color: rgba(249, 250, 251, 0.8);
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
  position: absolute;

  padding: 0px 24px;
  box-shadow: rgb(145 158 171 / 16%) 0px 8px 16px 0px;
}
.search-bar .input-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
}
.search-bar .input-box i {
  font-size: 20px;
  color: var(--gray-6);
}
.search-bar .input-box input {
  font: inherit;
  color: var(--second-color);
  font-weight: 500;
  padding: 4px 0px 5px;
  border: 0px;
  background: none;
  height: 100%;
  margin: 0px;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-duration: 10ms;
}
.search-bar .input-box input:focus {
  outline: none;
  box-shadow: none;
}
.search-bar .search-bar-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  line-height: 1.71429;
  font-size: 0.875rem;
  text-transform: capitalize;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 6px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(32, 101, 209);
  box-shadow: rgb(32 101 209 / 24%) 0px 8px 16px 0px;
}

.search-btn {
  color: rgb(99, 115, 129);
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

/* Responsive Styles 768px */
@media (max-width: 768px) {
}

/* Responsive Styles 1200px */
@media (max-width: 1200px) {
  .header {
    width: 100%;
    z-index: 22;
  }

  .nav-icons .menu-btn {
    display: inline-flex;
  }
}

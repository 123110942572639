/*********************
  Sidebar Css Styles *
  *******************/

.sidebar {
  position: fixed;
  width: 250px;
  height: 100vh;
  border-right: 2px solid rgb(241, 243, 244);
}

.sidebar-content-wrapper {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.logo {
  padding: 20px 15px;
}
.logo a:hover h2 {
  color: var(--primary-color) !important;
}
.logo h2 {
  font-size: 20px;
  color: var(--gray-6);
  transition: color 0.2s ease-in;
}
.logo h2 b {
  color: var(--primary-color);
}
.logo span {
  color: var(--primary-color);
  font-size: 12px;
  font-weight: 500;
  margin-top: -5px;
  display: block;
}

.user-box {
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}
.user-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 7px;
  padding: 16px 20px;
  border-radius: 9px;
  background-color: rgba(145, 158, 171, 0.12);
}
.user-wrapper .user-img {
  width: 40px;
  height: 40px;
}
.user-wrapper .user-img img {
  border-radius: 50%;
}
.user-wrapper .user-text {
  font-weight: 500;
  color: rgb(33, 43, 54);
  display: flex;
  flex-direction: column;
}
.user-text .logout {
  font-size: 12px;
  cursor: pointer;
  margin-top: -5px;
  transition: color 0.2s ease;
}
.user-text .logout:hover {
  color: var(--primary-color);
}

ul.sidebar-menu {
  list-style: none;
  margin: 0px;
  position: relative;
  padding: 30px 0 10px 0;
}

ul.sidebar-menu a {
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: var(--gray-6);
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 10px;
  transition: all 0.3s ease-out;
}
ul.sidebar-menu a:hover,
ul.sidebar-menu .active {
  color: rgb(33, 43, 54);
  background-color: rgba(145, 158, 171, 0.16);
  font-weight: 500;
}

/* Responsive Styles 1200px */
@media (max-width: 1200px) {
  .sidebar {
    display: none;
    background-color: rgba(0, 0, 0, 0.53);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition-delay: 0.2s;
    transition-duration: 0.4s;
    visibility: hidden;
    width: 100%;
    z-index: 99999;
  }
  .sidebar.active {
    display: block;
    visibility: visible;
    opacity: 1;
  }

  .active .sidebar-content {
    width: 250px;
    height: 100%;
    background-color: var(--white-color);
  }
}

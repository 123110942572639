/* Table */
.main-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}
.table-header {
  position: relative;
  align-items: center;
  min-height: 56px;
  height: 96px;
  display: flex;
  justify-content: space-between;
  padding: 0px 8px 0px 24px;
}
.table-header .table-search {
  line-height: 1.4375em;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(33, 43, 54);
  border: 1px solid rgb(192, 204, 214);
  cursor: text;
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 6px;
  padding-left: 14px;
  width: 240px;
}
.table-search .icon {
  display: flex;
  height: 0.01em;
  max-height: 2em;
  align-items: center;
  white-space: nowrap;
  color: rgb(99, 115, 129);
  margin-right: 8px;
}
.table-search input {
  font: inherit;
  letter-spacing: inherit;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  display: block;
  min-width: 0px;
  width: 100%;
  padding: 16.5px 14px 16.5px 0px;
}
.table-search input:focus {
  outline: 0px;
}
.table-footer {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  min-height: 52px;
  padding-right: 2px;
}

.main-table th {
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(241, 243, 244);
  text-align: left;
  padding: 16px;
  color: rgb(99, 115, 129);
  background-color: rgb(244, 246, 248);
}
.main-table td {
  line-height: 1.57143;
  font-size: 0.875rem;
  font-weight: 400;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgb(241, 243, 244);
  text-align: left;
  padding: 16px;
  color: rgb(33, 43, 54);
}
.main-table th:first-child {
  padding: 8px;
}
.main-table thead {
  display: table-header-group;
}
.main-table tbody {
  display: table-row-group;
}
.main-table tbody tr:hover {
  background-color: rgba(145, 158, 171, 0.08);
}
.main-table tbody th {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0px;
}
.main-table .t-btn {
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.main-table .t-btn:hover {
  color: var(--primary-color);
}
.main-table .table-img {
  width: 65px;
  height: 65px;
  padding: 3px;
}
.table-img > img {
  width: 100%;
  height: 100%;
}

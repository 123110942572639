.dashboard .p-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--gray-8);
  margin-bottom: 2rem;
}

.Mcard {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-image: none;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  z-index: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  box-shadow: none;
  text-align: center;
  color: rgb(122, 79, 1);
  background-color: rgb(255, 247, 205);
  cursor: pointer;
}
.Mcard:hover {
  transform: scale(1.1);
}

.Mcard .Mcard-icon {
  margin: auto auto 24px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  width: 74px;
  height: 74px;
  justify-content: center;
  font-size: 1.7rem;
  color: rgb(122, 79, 1);
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.24) 100%
  );
}
.Mcard h3.Mcard-num {
  margin: 0px;
  font-weight: 700;
  line-height: 1.5;
  font-size: 2rem;
}

.Mcard h6.Mcard-title {
  margin: 0px;
  font-weight: 700;
  line-height: 1.57143;
  font-size: 0.975rem;
  opacity: 0.72;
}

@media (max-width: 992px) {
  .Mcard {
    margin-bottom: 30px;
  }
}

/***************
  Login Styles *
****************/
.login-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fee url(../assets/images/signin-bg.jpg) no-repeat;
  background-size: cover;
}
.auth__form .form-control {
  min-height: 60px;
  transition: all 0.3s ease-in-out;
}
.auth__form .form-control:focus {
  box-shadow: none;
  border: 2px solid var(--primary-color);
}
.form__group {
  display: flex;
  align-items: unset !important;
  justify-content: space-between;
  column-gap: 2rem;
}
.form__group_item {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.btn-primary:hover {
  background-color: var(--second-color) !important;
  border-color: var(--second-color) !important;
}

.modal-content {
  min-height: 250px !important;
  border-radius: 0 !important;
}

.modal-content .modal-header {
  border-bottom: none;
}

.modal-content .modal-header .btn-close {
  margin: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 11;
}

.modal-content .modal-header .btn-close:focus {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}
/******************************* */
.form-control:focus {
  box-shadow: none;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

/*************************************************
*********** Product Upload Image ****************
*************************************************/
.upload__options {
  position: relative;
  cursor: pointer;
  text-align: center;
  /* background-color: rgba(220, 220, 220, 0.763); */
  background-color: rgba(145, 158, 171, 0.16);
  display: block;
  margin: 10px auto;
  border-radius: 5px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  border-radius: 7px;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload__options input {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: -1;
}
.add__new__user .upload__options .img__preview > img {
  width: 100% !important;
  height: 100% !important;
}
.add__new__user .upload__options label {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
}
.add__new__user .upload__options label:before {
  left: 25%;
  top: 25%;
  font-size: 2rem;
  border: none !important;
}
.add__new__user .upload__options label:hover,
.add__new__user .upload__options label:hover::before {
  color: var(--second-color);
  border: none;
  transform: none;
}
.upload__options label {
  width: 100%;
  height: 100%;
  font-weight: 400;
  cursor: pointer;
  overflow: hidden;
  z-index: 99;
  border: transparent;
  box-shadow: none;
  transition: all 0.3s linear;
}
.upload__options label:hover,
.upload__options label:hover::before {
  color: var(--second-color);
  border: 2px solid var(--second-color);
  transform: scale(1.1);
}
.upload__options label:before {
  content: "\ed82";
  font-family: "remixicon" !important;
  font-style: normal;
  font-size: 1.5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 30%;
  right: 35%;
  z-index: 1;
  width: 60px;
  height: 60px;
  color: var(--primary-color);
  /* background-color: #fff; */
  border: 2px solid var(--primary-color);
  border-radius: 15px;
  transition: all 0.3s linear;
}
.is_uploaded label:before {
  display: none;
}

.upload__options .img__preview {
  width: 100%;
}
